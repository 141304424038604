import { TActionName } from '@components/common/CardGrid/type';
import { SORT_CODE } from '@components/filter/constants';
import { PAGE_NAME } from 'src/constants';
import { AddCartTypes } from '@lib/mixpanel/events.constants';
import { UserInfoStatus } from '../../provider';

export interface IProductClickEvent {
  pageName: string;
  actionName: TActionName;
  productName: string;
  productNo: string;
  order: number;
  groupTitle?: string;
  groupSno?: number;
  keyword?: string;
  discountRate?: number;
}

export interface IShareViewEvent {
  url: string;
  share: string;
  variant: string;
  productNo: string;
}

export interface IJoinEvent {
  type?: string;
  productNo?: string;
  categoryCode?: string;
  keyword?: string;
}

type TChecked = 'checked' | 'unchecked';

export interface IFilterEvent {
  extensionValue?: string;
  extensionType?: TChecked;
  industryValue?: string;
  industryType?: TChecked;
  applicationValue?: string;
  applicationType?: TChecked;
  minPrice?: null | number;
  maxPrice?: null | number;
  filterReset?: 'y';
  pageName: PAGE_NAME;
}

export interface ISortOrderEvent {
  sortType: (typeof SORT_CODE)[keyof typeof SORT_CODE];
  pageName: PAGE_NAME;
}

export interface ISortOrderEventV2 {
  sortType: string;
  pageName: PAGE_NAME;
}

export interface IScrapEvent {
  productNos: number[];
  productStatus: string[];
  type: 'product card' | 'product detail';
}

export interface IAddCartFromScrapLog {
  productNos: number[];
}

export interface IBuyCartLog {
  discountAmount: number;
  amount: number;
  status: string;
  productNos: number[];
}

export interface ICheckoutSuccessLog {
  orderNo: string;
  amount: number;
  usedAconCash: number;
  usedPoint: number;
  usedCoupon: number;
  productNos: number[];
}

export interface IDirectBuyLog {
  title: string;
  price: number;
  discountRate: number;
  brand: string;
  extensions: string[];
  reviewCount: number;
  viewCount: number;
  isAconOnly: boolean;
  isDiscount: boolean;
  productNos: number[];
}

export interface IGNBClickLog {
  name: string;
  destination_url?: string; // 이동할 url, full url이어야 함 (https://www.acon3d.com/...)
}

export interface IButtonClickLog {
  actionName: string;
  userId?: string;
  orderNo?: number;
  productNo?: number;
  destination_url?: string; // 이동할 url, full url이어야 함 (https://www.acon3d.com/...)
  value?: string | number;
  categoryName?: string;
  categoryCode?: string;
  brandCount?: number;
  brandIndex?: number;
}

export interface IProductListLog {
  type: string;
  extensions: string[];
  sort?: string;
  categoryCode?: string;
  brandCode?: string;
  page?: number;
}

export interface IClickBannerLog {
  type: 'Slide Banner' | 'Middle Banner' | 'Fixed Banner';
  link?: string;
  value?: string | number;
}

export interface IClickTagLog {
  alias: string;
  tagType: string;
  isAdult?: boolean;
}

export type AddCartType = (typeof AddCartTypes)[keyof typeof AddCartTypes];
export type AddCartLogProps = {
  type: AddCartType;
  goodsNos: number | number[];
  'Product Nos': number | number[];
  status: UserInfoStatus;
};

export enum MixPanelMethodKey {
  BRAND = 'brand',
  KEYWORDS = 'keywords',
  POPULAR = 'popular',
}
