import { Global, css } from '@emotion/react';
import { FC } from 'react';
import { fontFace } from '@styles/fontFaceStyle';
import { LANGUAGE_CODE } from '../constants';

const GlobalStyle = css`
  $azstorage: 'https://storage.acon3d.com';

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  }

  body {
    overflow-x: hidden;
    .noscroll {
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  a {
    color: #252525;
    text-decoration: none;
  }

  .imp-frame-html5_inicis {
    position: fixed !important;
    z-index: 10000;
  }
  .noscroll {
    overflow: hidden;
  }
`;

const GlobalStyleComponent: FC<{ lang?: string }> = ({ lang }: { lang?: string }) => {
  const dependStyle = {
    ['body, body *']: {
      margin: 0,
      padding: 0,
      fontFamily:
        lang === LANGUAGE_CODE.CN
          ? 'arial,"pingfang sc","microsoft yahei",sans-serif'
          : lang === LANGUAGE_CODE.JP
          ? '"Pretendard JP Variable", Pretendard JP'
          : '"Pretendard Variable", Pretendard',
    },
  };

  return (
    <Global
      styles={css`
        ${GlobalStyle}
        ${dependStyle}
        ${lang !== LANGUAGE_CODE.CN && fontFace}
      `}
    />
  );
};

export default GlobalStyleComponent;
