import styled from '@emotion/styled';
import { ContentsContainer } from '@components/_new_components/Common/layout/Contents/Contents.styles';

export const ContentsWrapper = styled.div<{ isHideSideCategory: boolean; isFullWidthContents: boolean }>`
  display: flex;
  flex: 1;
  max-width: 2560px;
  justify-content: center;
  width: 100%;
  ${(props) =>
    props.isHideSideCategory &&
    `    
      & > ${ContentsContainer} {
        width: 100%;
      }
  `}
  ${(props) =>
    !props.isFullWidthContents &&
    `
    max-width: 100%;
    & > ${ContentsContainer} {
        max-width: calc(1440px + var(--content-side-padding) * 2px);
      }
  `}
`;
