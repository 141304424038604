import React from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import * as mixpanel from '@lib/mixpanel';
import useSearchInput from '@hooks/search/useSearchInput';
import useRecentSearches from '@hooks/search/useRecentSearches';
import usePopularSearches from '@hooks/search/usePopularSearches';
import {
  PopularSearchRollingDropdown,
  PopularSearchRollingWrapper,
  SearchContainer,
  SearchDropDown,
  SearchIconBox,
  SearchInput,
} from '@components/_new_components/Common/layout/Header/Gnb/search/Search.styles';
import { buttonClickLog } from '@lib/mixpanel/events';
import SearchAutoComplete from '@components/_new_components/Common/Search/SearchAutoComplete';
import useSearchAutoComplete from '@hooks/search/useSearchAutoComplete';
import { MIX_PANEL_METHOD_MESSAGE } from '@lib/mixpanel/events.constants';
import { MixPanelMethodKey } from '@lib/mixpanel/type';
import { ADULT_SEARCH_KEYWORD } from '@components/productList/ProductList.constants';
import SearchIcon from '../../../../../../../src/assets/icons/gnb/SearchIcon';
import { PATH_PAGE } from '../../../../../../../src/routes/paths';
import { autoCompleteQueryDataKeys } from '../../../../../../../src/types/search/Search.types';

const RecentSearches = dynamic(() => import('@components/_new_components/Common/Search/RecentSearches'), {
  ssr: false,
});

const PopularSearches = dynamic(() => import('@components/_new_components/Common/Search/PopularSearches'), {
  ssr: false,
});

const PopularSearchesRolling = dynamic(() => import('@components/_new_components/Common/layout/Header/Gnb/popularSearchesRolling/PopularSearchesRolling'), {
  ssr: false,
});

export default function Search() {
  const router = useRouter();
  const { t } = useTranslation();
  const rollingWrapper = React.useRef<HTMLDivElement>(null);
  const [showPopularDropdown, setShowPopularDropdown] = React.useState(false);

  const searchInput = useSearchInput();
  const recentSearches = useRecentSearches();
  const popularSearches = usePopularSearches();
  const searchAutoComplete = useSearchAutoComplete();

  const triggerSearchLogAndBlur = (keyword?: string, key?: MixPanelMethodKey) => {
    const isFilteredKeywrod = ADULT_SEARCH_KEYWORD.find((item) => item.keyword === searchInput.keyword);

    mixpanel.event('Trigger Search', {
      keyword: keyword ? keyword : searchInput.keyword,
      method: key ? MIX_PANEL_METHOD_MESSAGE[key] : 'Recent Searches',
      adult: Boolean(isFilteredKeywrod?.keyword),
    });

    searchInput.handleInputBlur();
  };

  const routeSearch = () => {
    if (!searchInput.keyword?.trim()) {
      alert(t('menu:gnb.search.placeholder'));
      searchInput.handleSearchInputClear();
      return;
    }

    triggerSearchLogAndBlur();

    if (searchAutoComplete.activeItem) {
      if (searchAutoComplete.activeItem.type === 'keywords') {
        recentSearches.pushRecentItem(searchAutoComplete.activeItem.value);
        searchAutoComplete.changeSearchKeyword();
        buttonClickLog({ actionName: 'Search Suggestion on Related Searches' });
        router.push({ pathname: PATH_PAGE.search, query: { keyword: searchAutoComplete.activeItem.value } });
      } else if (searchAutoComplete.activeItem.type === 'brand') {
        searchInput.handleSearchInputClear();
        buttonClickLog({ actionName: 'Search Suggestion on Brands' });
        router.push({ pathname: `/brand/${searchAutoComplete.activeItem.value}` });
      }
    } else {
      recentSearches.pushRecentItem(searchInput.keyword);
      router.push({ pathname: PATH_PAGE.search, query: { keyword: searchInput.keyword } });
    }
  };

  const handleRecentItemClick = (keyword: string) => {
    triggerSearchLogAndBlur(keyword);
    recentSearches.handleRecentItemClick(keyword);
  };

  const handlePopularItemClick = (keyword: string) => {
    triggerSearchLogAndBlur(keyword, MixPanelMethodKey.POPULAR);
    setShowPopularDropdown(false);
    popularSearches.handlePopularItemClick(keyword);
  };

  const handleAutoCompleteItemClick = (key: autoCompleteQueryDataKeys, value) => {
    typeof value === 'string' ? triggerSearchLogAndBlur(value, MixPanelMethodKey.KEYWORDS) : triggerSearchLogAndBlur(value?.name, MixPanelMethodKey.BRAND);
    searchAutoComplete.handleAutoCompleteItemClick(key, value);
  };

  const handleSearchInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const keycode = e.keyCode;
    if (keycode === 13) routeSearch();
    if (keycode === 38) searchAutoComplete.decreaseActiveItemIndex();
    if (keycode === 40) searchAutoComplete.increaseActiveItemIndex();

    if ([38, 40].includes(keycode)) e.preventDefault();
  };

  const handlePopularRollingWrapperMouseOver = () => {
    if (showPopularDropdown) return;
    mixpanel.event('Page view', { type: 'Popular Searches Dialog' });
    setShowPopularDropdown(true);
  };

  const handlePopularRollingWrapperMouseOut = () => {
    if (!rollingWrapper) return;
    setShowPopularDropdown(false);
  };

  return (
    <React.Fragment>
      <SearchContainer>
        <SearchInput
          ref={searchInput.inputRef}
          value={searchInput.keyword}
          onChange={searchInput.handleSearchInputChange}
          onClick={searchInput.handleInputFocus}
          onFocus={searchInput.handleInputFocus}
          onBlur={searchInput.handleInputBlur}
          onKeyDown={handleSearchInputKeyDown}
          placeholder={t('menu:search.placeholder')}
        />
        <SearchIconBox onClick={routeSearch}>
          <SearchIcon />
        </SearchIconBox>

        {/** 최근 검색어 */}
        {searchInput.isFocus && !searchAutoComplete.debounceKeyword && recentSearches.recentItems.length > 0 && (
          <SearchDropDown onMouseDown={(e) => e.preventDefault()}>
            <RecentSearches
              recentItems={recentSearches.recentItems}
              onClickRemoveAllRecentItems={recentSearches.handleRemoveAllRecentItemsClick}
              onClickRemoveRecentItem={recentSearches.handleRemoveRecentItemClick}
              onClickRecentItem={handleRecentItemClick}
            />
          </SearchDropDown>
        )}

        {/** 자동 완성 */}
        {searchInput.isFocus && searchAutoComplete.debounceKeyword && !searchAutoComplete.isEmptyData && (
          <SearchDropDown onMouseDown={(e) => e.preventDefault()}>
            <SearchAutoComplete
              data={searchAutoComplete.data}
              keyword={searchAutoComplete.debounceKeyword}
              activeItem={searchAutoComplete.activeItem}
              onClickAutoCompleteItem={handleAutoCompleteItemClick}
              keywordsLimit={5}
            />
          </SearchDropDown>
        )}
      </SearchContainer>

      {/** 인기 검색어 Rolling 및 Dropdown */}
      <PopularSearchRollingWrapper ref={rollingWrapper} onMouseOver={handlePopularRollingWrapperMouseOver} onMouseLeave={handlePopularRollingWrapperMouseOut}>
        {popularSearches.popularItems.length > 0 && <PopularSearchesRolling popularItems={popularSearches.popularItems} />}

        {showPopularDropdown && (
          <PopularSearchRollingDropdown onMouseDown={(e) => e.preventDefault()}>
            <PopularSearches popularItems={popularSearches.popularItems} baseDateTime={popularSearches.baseDateTime} onClickPopularItem={handlePopularItemClick} />
          </PopularSearchRollingDropdown>
        )}
      </PopularSearchRollingWrapper>
    </React.Fragment>
  );
}
